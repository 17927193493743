<template>
  <div id="start-up-box">
    <div class="bgimg">
      <div :class="isLogo ? 'logo logo-active' : 'logo'">
      
      </div>
      <div class="logo-content">
        <h1 class="app-name">
                {{$t('logo.name')}}
            </h1>
        <p class="msg">{{$t('logo.msg')}}</p>
      </div>
      <img class="dibubg" src="../../assets/images/logobut.png" alt="">
    </div>
  </div>
</template>
<script>
export default {
  name: "logo",
  data() {
    return {
      isLogo: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLogo = true;
      setTimeout(() => {
        this.$router.push('/sign')
      }, 800);
    }, 500);
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
#start-up-box {
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, #FFBFFF 0%, #FD87FD 100%);

  .bgimg {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-image: url("../../assets/images/logobg2.png");
    background-position: center center;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .dibubg{
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .logo {
    width: 76px;
    height: 76px;
    margin-bottom: 64px;
    background-color: #f5f5f5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s linear;
    background-image: url('../../assets/images/tklogo.png');
    background-size: 100% 100%;
    opacity: 0.3;
    position: relative;
    z-index: 1;
  }

  .logo-active {
    width: 180px;
    height: 180px;
    border-radius: 50px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
  }

  .logo-content {
    text-align: center;
    position: relative;
    z-index: 1;
  }

  .logo-content {
    color: rgba(255, 255, 255, 0.8);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    margin-bottom: 130px;

    .app-name {
      color: #fff;
      font-family: Inter;
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .url {
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    bottom: 19px;
  }
}
</style>
